import React from 'react';
import { Link } from 'react-router-dom';

const RegistrationButton = ({className}) => {
    return (
        <div className={`buy-button ${className || ''}`}>
            <Link to={"/registration"} className="btn btn-primary">Login</Link>
        </div>
    );
}
 
export default RegistrationButton;