import React, {useEffect} from 'react';
import { Link, NavLink } from 'react-router-dom';
import FirebaseContext from '../context/firebase';
import { toast } from 'react-toastify';

const Navigation = (props) => {
    const firebase = React.useContext(FirebaseContext);
    const [loading, setLoading] = React.useState(false);
    const logOut = async () => {
        try {
            setLoading(true);
            await firebase.auth().signOut();
        } catch (error) {
            toast.error("Error in logging out");
            setLoading(false);
        }
    }
    useEffect(() => {
        //Sticky
        const $ = window.$;
        $('.navbar-toggle').on('click', function (event) {
            $(this).toggleClass('open');
            $('#navigation').slideToggle(500);
        });

        $(window).scroll(function() {
            var scroll = $(window).scrollTop();

            if (scroll >= 50) {
                $(".sticky").addClass("nav-sticky");
            } else {
                $(".sticky").removeClass("nav-sticky");
            }
        });        

        $('.mouse-down').on('click', function(event) {
            var $anchor = $(this);
            $('html, body').stop().animate({
                scrollTop: $($anchor.attr('href')).offset().top - 0
            }, 1500, 'easeInOutExpo');
            event.preventDefault();
        });
    }, [])
    return (
        <div id="navigation"> 
            <ul className="navigation-menu">
                <li><NavLink activeClassName={"active"} to="/">Home</NavLink></li>
                <li><NavLink activeClassName={"active"} to="/about">About US</NavLink></li>
                <li><NavLink activeClassName={"active"} to="/schedule">Schedule</NavLink></li>
                <li><NavLink activeClassName={"active"} to="/contact">Contact Details</NavLink></li>
                <li><NavLink activeClassName={"active"} to="/faq">FAQ</NavLink></li>
                {
                    //For mobile
                    props.user && props.user.uid &&
                    <>
                        <li className={"d-md-none"}><Link to="/registration/profile">Profile</Link></li>
                        <li className={"d-md-none"}><a href="#" onClick={logOut}>Log Out</a></li>
                        
                    </>
                }
                {
                    !props.user && <li className={"d-md-none"}><Link to="/registration">Login</Link></li>
                }
            </ul>
                
        </div>
    );
}
 
export default Navigation;