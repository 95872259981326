import React from 'react';
import Navigation from './Navigation';
import RegistrationButton from '../components/RegistrationButton';
import { Link } from 'react-router-dom';
import FirebaseContext from '../context/firebase';
import { toast } from 'react-toastify';

const Header = (props) => {
    const firebase = React.useContext(FirebaseContext);
    const [loading, setLoading] = React.useState(false);
    const logOut = async () => {
        try {
            setLoading(true);
            await firebase.auth().signOut();
        } catch (error) {
            toast.error("Error in logging out");
            setLoading(false);
        }
    }
    return (
        <header id="topnav" className="defaultscroll sticky">
            <div className="container">
                <div>
                    <Link className="logo" to="/">
                        <img src="/images/iasst-logo.jpg" width={70} className={"pt-1 pb-1"} style={{marginRight: 15}} alt={"IASST Logo"}/>
                        <span className="text-primary">NER BIF</span> Facility
                    </Link>
                </div> 

                {
                    props.user 
                    ? <>
                        <div className="buy-button">
                            <Link to={"/registration"} className="btn btn-primary d-none d-md-inline-block">Profile</Link>
                            <button className={"btn btn-link d-none d-md-inline-block"} onClick={logOut}>
                            {
                                loading 
                                ? <div className="spinner-border text-light" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div> 
                                : "Log Out"

                            }
                            </button>
                        </div>
                        
                      </>
                    : <RegistrationButton className={"d-none d-md-inline-block"}/>
                }

                <div className="menu-extras">
                    <div className="menu-item">
                        <a className="navbar-toggle" href={"#"}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </a>                        
                    </div>
                </div>

                <Navigation user={props.user}/>

            </div>
        </header>
    );
}
 
export default Header;