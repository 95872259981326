import React from 'react';

const Loader = () => {
    return (
        <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
}

const ContentLoader = ({height, position, loader}) => {
    return (
        <div style={{position: position || 'absolute', top:0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(255,255,255, 0.95)', zIndex: 1000, minHeight: height || 300, backdropFilter: 'blur(25px) contrst(0.8)'}} className={"d-flex justify-content-center align-items-center"}>
            <div className={`${loader || "spinner-grow"} text-success`} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export {Loader, ContentLoader};