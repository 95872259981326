import React, {Suspense, lazy, useContext, useEffect, useState} from 'react';
import {Switch, Route, useLocation} from 'react-router-dom';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import {ToastContainer} from 'react-toastify';
import FirebaseContext from './context/firebase';
import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { ContentLoader } from './components/Loader';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const Home = lazy(() => import('./pages'));
const Registration = lazy(() => import('./pages/registration/index'));
const NotFound = lazy(() => import('./pages/not-found'));
const AboutUs =  lazy(() => import('./pages/about'));
const Schedule = lazy(() => import('./pages/schedule'));
const ContactUs = lazy(() => import('./pages/contact'));
const FAQ = lazy(() => import('./pages/faq'));

const Loader = () => {
    return (
        <section className="mt-5 pt-5">
            <div className="container"> 
                <ContentLoader height={"60vh"} position={"relative"}/>
            </div>
        </section>
    )
}

function App() {
  const firebase = useContext(FirebaseContext);
  const [user, setUser] = useState(null);
  useEffect(() => {
    const $ = window.$;
    // Back to top
    $(window).scroll(function(){
        if ($(this).scrollTop() > 100) {
            $('.back-to-top').fadeIn();
        } else {
            $('.back-to-top').fadeOut();
        }
    }); 

    $('.back-to-top').click(function(){
        $("html, body").animate({ scrollTop: 0 }, 3000);
        return false;
    }); 

    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
        setUser(user);
        } else {
        setUser(null);
        }
    });
  }, []);
  return (
    <div className="App">   

      <Header user={user}/>
      <Route render={({location}) => (          
            <TransitionGroup>
                    <CSSTransition key={location.key} timeout={{enter: 300, exit: 300}} classNames={"fade"}>
                        <Suspense fallback={<Loader />}>
                            <Switch location={location}>   
                                <Route path={"/"} exact component={Home}/>
                                <Route path={"/registration"} component={Registration}/>
                                <Route path={"/about"} exact component={AboutUs}/>
                                <Route path={"/schedule"} exact component={Schedule}/>
                                <Route path={"/contact"} exact component={ContactUs}/>
                                <Route path={"/faq"} exact component={FAQ}/>
                                <Route component={NotFound}/>
                            </Switch>
                        </Suspense>
                    </CSSTransition>
                </TransitionGroup>
            
      )}/>      

      <ToastContainer 
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        />

      <Footer/>
    </div>
  );
}

export default (props) => {
    const { pathname } = useLocation();
    useEffect(() => {
        //window.$("html, body").animate({ scrollTop: 0 }, 3000);
        window.scrollTo(0, 0);
    }, [pathname]);

    return <App {...props}/>
};