import React, { useEffect } from 'react';
import FirebaseContext from './firebase';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
    apiKey: "AIzaSyDZL45dpsqifE00c2YVvBjdU6XI5dRvqiw",
    authDomain: "iasst-bif.firebaseapp.com",
    databaseURL: "https://iasst-bif.firebaseio.com",
    projectId: "iasst-bif",
    storageBucket: "iasst-bif.appspot.com",
    messagingSenderId: "5461477165",
    appId: "1:5461477165:web:fa06f70086cf7287c8f5a4",
    measurementId: "G-PZG5MDEQ0S"
};

const FirebaseProvider = ({children}) => {
    if(!firebase.apps.length) {
        firebase.initializeApp(config)
    }

    //firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    return(
        <FirebaseContext.Provider value={firebase}>
            {
                children
            }
        </FirebaseContext.Provider>
    )
}

export default FirebaseProvider;