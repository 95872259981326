import React from 'react';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <>
         <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <h4 className="text-light footer-head">Institute of Advanced Study in Science and Technology</h4>
                        <p className="mt-4 text-foot">Institute of Advanced Study in Science and Technology (IASST) is a premier scientific research organization in the north-east region of India. The Institute is engaged in multi disciplinary research activities, both in fundamental and applied, across frontier areas of science and technology in Physical, Chemical as well as Life Sciences. <a href={"http://iasst.gov.in"}>Read More</a></p>                        
                    </div>
                    
                    <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Quick Link</h4>
                        <ul className="list-unstyled footer-list mt-4">
                            <li><Link to="/about" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> BIF Facility</Link></li>
                            <li><a href="http://iasst.gov.in" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> About IASST</a></li>
                            <li><a href="" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Team</a></li> 
                            <li><Link to="/registration" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Registration </Link></li> 
                            <li><Link to="/faq" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> FAQ</Link></li>                          
                        </ul>
                    </div>
                    
                    

                    <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Contact Details</h4>
                        <ul className="list-unstyled footer-list mt-4">
                            <li><span className="mt-4 text-foot">Institute of Advanced Study in Science and Technology, Paschim Boragaon, Gorchuk, Guwahati, Assam, India.</span></li>
                            <li><span className="mt-4 text-foot">Kangkon Saikia, kangkonsaikia@iasst.res.in</span></li>
                            <li><span className="mt-4 text-foot">+91 8399 8399 81 (Mon - Fri: 9AM - 5PM)</span></li>
                        </ul>
                                            
                    </div>
                </div>
            </div>
        </footer>
        {
            //Footer small
        }
        <footer className="footer footer-bar">
            <div className="container text-center">
                <div className="row align-items-center">
                    <div className="col-sm-6">
                        <div className="text-sm-left">
                            <p className="mb-0">Bioinformatics Infrastructure Facility,</p>
                        </div>
                    </div>

                    <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <p>Website Design and Development - Kangkon Saikia</p>
                    </div>
                </div>
            </div>
        </footer>
        </>
    );
}
 
export default Footer;